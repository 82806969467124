import { Toolbar, Box, Typography } from "@mui/material";
import OnboardingDrawer from "./OnbardingDrawer";
import { Routes, Route } from "react-router-dom";
import MandateCreation from "./MandateCreation";
import CauseCreation from "./CauseCreation";

function Onboarding() {
  const drawerWidth = 240;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        p: 3,
        ml: `${drawerWidth}px`,
      }}
    >
      <Toolbar />
      <OnboardingDrawer />
      <Typography component="h1" variant="h1">
        Cause Onboarding
      </Typography>
      <Routes>
        <Route path="cause" element={<CauseCreation />} />
        <Route path="mandate" element={<MandateCreation />} />
      </Routes>
    </Box>
  );
}

export default Onboarding;
