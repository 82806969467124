import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#095262",
    },
    secondary: {
      main: "#039ba0",
    },
    background: {
      default: "#f4f6f8",
    },
    error: {
      main: "#f8531b",
    },
  },
  typography: {
    fontFamily:
      "Syncopate Bold, Intro Light, Intro Regular Italic, Intro Regular, Arial, sans-serif",
    h1: {
      fontSize: "2em",
      fontWeight: "bold",
      fontFamily: "Syncopate Bold",
      color: "#095262",
    },
    h2: {
      fontSize: "1.9em",
      fontFamily: "Intro Regular",
      color: "#039ba0",
    },
    h6: {
      font: "Syncopate Bold",
    },
    button: {
      fontFamily: "Intro Regular",
    },
    body1: {
      fontFamily: "Intro Regular",
      color: "#095262",
    },
    body2: {
      fontFamily: "Intro Light",
      color: "#095262",
    },
  },
  components: {
    MuiToolbar: {},
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "1.2em",
          fontFamily: "Intro Regular",
          color: "#f8531b",
        },
      },
    },
  },
});

export default theme;
