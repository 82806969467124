import axiosInstance from "../config/AxiosInstance";
import { HEADER_JSON } from "../constants";
import { CauseInputDto } from "../entities/CauseInputDto";

async function getCauseForMandateCreation() {
    const response = await axiosInstance.get(
        `/causes/without-mandate`,
        HEADER_JSON
    );
    return response.data;
}

async function createCause(CauseInputDto: CauseInputDto) {
    const response = await axiosInstance.post(
        `/causes`,
        CauseInputDto,
        HEADER_JSON
    );
    return response.data;
}

export { getCauseForMandateCreation, createCause };